 <template>
  <validation-observer ref="AddPolicyForm">
    <form ref="form" @submit.prevent="addPolicy">
      <b-form-group label="Policy Scope" label-for="Policy Scope">
        <validation-provider
          #default="{ errors }"
          name="Policy Scope"
          rules="required"
        >
          <b-form-select
            v-model="policyScope"
            :options="policyScopeOptions"
          ></b-form-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        v-if="this.policyScope === 'internal'"
        label="Organization"
        label-for="Organization"
      >
        <v-select
          v-model="organization"
          label="name"
          :options="organizations"
          :reduce="(organization) => organization.tenant_uid"
          disabled
        />
      </b-form-group>

      <b-form-group label="Policy Name" label-for="Policy Name">
        <validation-provider
          #default="{ errors }"
          name="Policy Name"
          rules="required"
        >
          <b-form-input
            id="policy_name"
            :state="errors.length > 0 ? false : null"
            v-model="policyName"
          ></b-form-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!--Upload File -->
      <b-form-group label="Upload File" label-for="File">
        <validation-provider
          #default="{ errors }"
          name="file"
          rules="required|ext:nessus"
        >
          <b-form-file
            v-model="doc_file"
            :state="Boolean(doc_file)"
            ref="file"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            v-on:change="handleFileUpload()"
          ></b-form-file>
          <!-- <input type="file" @change="filesChange()" ref="file" /> -->

          <div class="mt-1">
            Selected file: {{ doc_file ? doc_file.name : "" }}
          </div>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-button type="submit" variant="primary" :disabled="isdisable" size="sm">
        <span v-if="isloading"
          ><b-spinner type="border" small></b-spinner> Please wait</span
        >
        <span v-else> Create</span>
      </b-button>
    </form>
  </validation-observer>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BFormSelect,
  BSpinner,
  BFormFile,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BSpinner,
    BFormFile,
    vSelect,
  },
  props: {
    callBack: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isloading: false,
      isdisable: false,
      policyName: "",
      doc_file: null,
      policyScope: "public",
      policyScopeOptions: [
        { value: "public", text: "Public" },
        { value: "internal", text: "Internal" },
      ],
      organization: this.$store.state.app.org_id,
      organizations: this.$store.state.app.workspaces,
      org_id: parseInt(this.$store.state.app.organizationId),
    };
  },
  created: function () {
    // this.load();
  },
  methods: {
    addPolicy: function () {
      this.isloading = true;
      this.isdisable = true;
      let formData = new FormData();
      formData.append("policy_name", this.policyName);
      formData.append("file", this.doc_file);
      formData.append("policy_scop", this.policyScope);
      if (this.policyScope === "internal") {
        formData.append("organization", this.org_id);
      }
      const options = {
        method: "POST",
        data: formData,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/nessus-policy/upload-policy/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.callBack();
        }
        this.isloading = false;
        this.isdisable = false;
      });
    },
    handleFileUpload() {
      this.doc_file = this.$refs.file.files[0];
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>