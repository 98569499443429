<template>
  <b-card-code title="">
    <b-button
      type="button"
      variant="primary"
      class="btn ml-auto m-1"
      size="sm"
      v-if="
        this.$store.state.app.user.permissions.includes(
          'scan.add_nessuspolicy'
        )
      "
      @click="openAddScan()"
    >
      Add Profile
    </b-button>
    <b-table
      responsive
      id="scanpolicyTable"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      hover
    >
      <template #cell(index)="data">
        {{ data.index + 1 + perPage * (currentPage - 1) }}
      </template>
      
       <!-- <template #cell(policy_file)="data">
        {{ JSON.parse(data.item.policy_file).key }}
      </template> -->
      <template #cell(actions)="data">
        <b-button
          variant="outline-danger"
          size="sm"
          @click="gotoDelete(data.item.id, data.item.policy_name)"
          >Delete</b-button
        >
      </template>
    </b-table>
    <b-pagination-nav
      v-model="currentPage"
      :numberOfPages="total"
      :total-rows="rows"
      :link-gen="linkGen"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="scanpolicyTable"
      use-router
      v-if="!loading"
    />
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete
        <span class="text-primary">" {{ policyName }} "</span> ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteItem()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
    <b-modal
      ref="modal-add-policy"
      hide-footer
      modal-class="modal-primary"
      centered
      title="Add Nessus Profile"
      v-model="openAddModal"
      size="lg"
    >
      <AddPolicy :callBack="closeModal" />
    </b-modal>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BButton,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BModal,
  BSidebar,
  BCard,
  BCardText,
  BPaginationNav,
} from "bootstrap-vue";
import AddPolicy from "./addPolicy.vue";
export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BModal,
    BSidebar,
    BCard,
    BCardText,
    AddPolicy,
    BPaginationNav,
  },
  //   props: {
  //     org_id: {
  //       type: Number,
  //       required: true,
  //     },
  //   },
  data() {
    return {
      isUpdate: this.$store.state.app.user.permissions.includes(
        "asset.change_insecureport"
      ),
    //   isDelete: this.$store.state.app.user.permissions.includes(
    //     "asset.delete_insecureport"
    //   ),
      fields: [
        { key: "index", label: "#" },
        { key: "policy_name", label: "Name" },
        // { key: "policy_file", label: "File" },
        { key: "actions", label: "Actions" },
      ],
      pagination_pos: "center",
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      items: [],
      openDeleteModal: false,
      openAddModal: false,
      policyName: "",
      policy_id: null,
    };
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.load(false);
    },
  },
  created: function () {
    this.load();
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    load: function (reset = true) {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      if (reset) this.currentPage = 1;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/nessus-policy?page=" +
          this.currentPage,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
      });
    },

    openAddScan() {
      this.openAddModal = true;
    },

    closeModal: function () {
      this.$refs["modal-add-policy"].hide();
      this.load();
    },

    gotoDelete(id, policy_name) {
      this.openDeleteModal = true;
      this.policy_id = id;
      this.policyName = policy_name;
    },
    deleteItem() {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/nessus-policy/" +
          this.policy_id +
          "/",
      };
      this.$http(options).then((res) => {
        console.log(res);
        this.openDeleteModal = false;
        this.load();
      });
    },
  },
};
</script>